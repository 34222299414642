import React, { createContext, useContext, useEffect, useRef } from 'react';
import { fetchWeeklyPuzzle, getAllImageUrls } from '../utils/data.ts';
import { useOnyx } from 'react-native-onyx';
import Onyx from 'react-native-onyx';
import CONST from '../CONST';
import ONYXKEYS from '../lib/ONYXKEYS';

const GameContext = createContext();

export const useGameContext = () => useContext(GameContext);

export const GameProvider = ({ children }) => {
  const [gameData] = useOnyx(ONYXKEYS.GAME_DATA);
  const [gameState] = useOnyx(ONYXKEYS.GAME_STATE);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchWeeklyPuzzle();
        const imageUrlsData = await getAllImageUrls(data.id);
        data.imageUrls = imageUrlsData;
        console.log('game data pushed to ONYX:', { data });
        Onyx.set(ONYXKEYS.GAME_DATA, data); // Store the game data

        // TODO: UNCOMMENT THIS
        // if(data.puzzleID === gameState.puzzleID) {
        //   console.log("game state already exists, not updating");
        //   return;
        // }

        // Set the initial game state in Onyx
        Onyx.set(ONYXKEYS.GAME_STATE, {
          puzzleID: data.id,
          currentImageIndex: 0,
          currentGuessIndex: 0,
          pastGuesses: [],
          status: CONST.STATUS.NOT_STARTED,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const startGame = () => {
    const newgameState = {
      ...gameState,
      status: CONST.STATUS.IN_PROGRESS,
    };
    console.log('new game state:', { newgameState });
    Onyx.merge(ONYXKEYS.GAME_STATE, newgameState);
  };

  const updateGameState = (answer) => {
    console.log('update game state called with answer:', { answer, gameState });
    let gameStatus = gameState.status;
    if (answer === gameData.name) {
      gameStatus = CONST.STATUS.WON;
    } else if (gameState.currentGuessIndex >= gameData.imageUrls.length - 1) {
      gameStatus = CONST.STATUS.LOST;
    } else {
      gameStatus = CONST.STATUS.IN_PROGRESS;
    }
    const newgameState = {
      ...gameState,
      currentGuessIndex: gameState.currentGuessIndex + 1,
      currentImageIndex: gameState.currentImageIndex + 1,
      pastGuesses: [...gameState.pastGuesses, answer],
      status: gameStatus,
    };
    console.log('new game state:', { newgameState });
    Onyx.merge(ONYXKEYS.GAME_STATE, newgameState);
  };

  return (
    <GameContext.Provider value={{ gameData, gameState, startGame, updateGameState }}>
      {children}
    </GameContext.Provider>
  );
};
