import supabase from '../lib/supabaseClient';
import { Puzzle } from '../types/onyxTypes/Puzzle';

const fetchWeeklyPuzzle = async () => {
  const today = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD

  try {
    const { data, error } = await supabase
      .from('pubs')
      .select('*')
      .eq('is_published', true)
      .lte('release_date', today) // Fetch puzzles released up to today's date
      .order('release_date', { ascending: false })
      .limit(1); // Get the latest active puzzle

    if (error) throw error;
    const puzzle = data?.[0] || null;

    console.log('Fetched puzzle:', puzzle);

    if (puzzle) {
      const structuredPuzzle: Puzzle = {
        id: puzzle.id,
        name: puzzle.name.toUpperCase(),
        imageUrls: [], // this is not currently used
        description: puzzle.description,
        releaseDate: puzzle.release_date,
        location: puzzle.details.address,
        founded: puzzle.details.founded,
        fact: puzzle.details.fact,
      };
      return structuredPuzzle;
    }

    return null;
  } catch (error) {
    console.error('Error fetching weekly puzzle:', error);
    return null;
  }
};

async function getAllImageUrls(pubId: string | number) {
  const bucketName = 'pub_images';
  const folderName = `pub${pubId}`;

  const { data, error } = await supabase.storage.from(bucketName).list(folderName, {
    limit: 10,
    offset: 0,
  });

  console.log({ data, error });

  if (error) {
    console.error('Error listing files:', error);
    return [];
  }

  // For some reason the current query returns an .emptyFolderPlaceholder file
  const imageUrls = data.map((file) => {
    if (file.name === '.emptyFolderPlaceholder') {
      return null;
    }

    return `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${bucketName}/${folderName}/${file.name}`;
  });

  return imageUrls.filter((url) => url !== null);
}

export { fetchWeeklyPuzzle, getAllImageUrls };
