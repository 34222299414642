import React from 'react';
import { Text, TouchableOpacity, TextStyle, ViewStyle } from 'react-native';
import { IoBeerSharp } from 'react-icons/io5';
import useTheme from '../hooks/useTheme.ts';

// Define the prop type for SettingsButton
interface SettingsButtonProps {
  text: string; // The text prop that will be passed to the button
  onPress: () => void; // Callback to handle button press
}

function SettingsButton({ text, onPress }: SettingsButtonProps) {
  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <TouchableOpacity style={styles.settingsButton} onPress={onPress}>
      <IoBeerSharp color={theme.textPrimary} style={styles.settingsButton} />
    </TouchableOpacity>
  );
}

// Styles with optional typing
const getStyles = (theme) => ({
  settingsButton: {
    width: 60,
    height: 60,
    margin: 10,
    // backgroundColor: '#666',
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 10,
  } as ViewStyle,
  // settingsButtonText: {
  //   color: 'white',
  //   fontSize: 24,
  //   fontWeight: 'bold',
  // } as TextStyle,
});

export default SettingsButton;
