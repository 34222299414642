import React, { useState, useEffect } from 'react';
import { Text, View } from 'react-native';
import '../App.css';
import { useOnyx } from 'react-native-onyx';
import { Button } from 'react-native-web';
import { useGameContext } from '../context/GameContext';
import CONST from '../CONST';
import ONYXKEYS from '../lib/ONYXKEYS';
import Beer from '../components/Beer';
import SettingsButton from '../components/SettingsButton.tsx';
import ClueZone from '../components/ClueZone.tsx';
import useTheme from '../hooks/useTheme.ts';
import ResultsPage from '../components/ResultsPage.js';
import { IoBeerSharp } from "react-icons/io5";
import Settings from '../components/Settings.js';
import H1 from '../components/text/H1.tsx';
import H2 from '../components/text/H2.tsx';
import Body from '../components/text/Body.tsx';
import Title from '../components/text/Title.tsx';

function HomeScreen() {
  const [pubData] = useOnyx(ONYXKEYS.GAME_DATA);
  const { gameState, startGame } = useGameContext();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [isSettingsVisable, setSettingsVisable] = useState(false);
  const [isResultsVisable, setResultsVisable] = useState(false);

  const status = gameState?.status || '';

  useEffect(() => {
    // Apply the theme's background color to the body element
    document.body.style.backgroundColor = theme.appBG;
  }, [theme]); // Re-run this effect when the theme changes

  console.log({status});

  useEffect(() => {
    if (status === CONST.STATUS.WON || status === CONST.STATUS.LOST) {
      setResultsVisable(true);
    }
    else {
      setResultsVisable(false);
    }
  }, [status]);

  if (!gameState || !pubData || !styles) {
    return <Text style={styles.bodyText}>Loading...</Text>;
  }

  const getDebugInfo = () => {
    if (process.env.NODE_ENV !== 'production') {
      return (
        <>
          <Text style={styles.bodyText}>Current Guess: {gameState.currentGuessIndex}</Text>
          <Text style={styles.bodyText}>Current Image: {gameState.currentImageIndex}</Text>
          <Text style={styles.bodyText}>pastGuesses: {gameState.pastGuesses.join(', ')}</Text>
          <Text style={styles.bodyText}>Status: {status}</Text>
        </>
      );
    }
    return null; // Return nothing if not in development
  };

  const toggleSettingsVisibility = () => {
    setSettingsVisable((prevState) => !prevState);
  };

  const closeSettings = () => {
    setSettingsVisable(false);
  };

  const openResults = () => {
    setResultsVisable(true);
  };

  const closeResults = () => {
    setResultsVisable(false);
  };

  const getContent = (status) => {
    if (!Object.values(CONST.STATUS).includes(status)) {
      return <Text style={styles.bodyText}>Houston, we have a problem...</Text>;
    }
    switch (status) {
      case CONST.STATUS.NOT_STARTED:
        return (
          <>
            <H1 style={styles.h1}>THINK YOU KNOW YOUR PUBS?</H1>
            <Body style={styles.body}>
              5 CLUES
              <br />5 GUESSES
              <br />1 PUB GENIUS
            </Body>
            <Button
              title="Start"
              style={styles.enterButton}
              onPress={() => {
                startGame();
              }}
            ></Button>
            <H2>NEW CHALLENGE EVERY THURSDAY</H2>
          </>
        );
      case CONST.STATUS.IN_PROGRESS:
        return (
          <>
            <ClueZone imageURL={pubData.imageUrls[gameState.currentImageIndex]} />
          </>
        );
      case CONST.STATUS.WON:
      case CONST.STATUS.LOST:
      default:
        return <Button title="What da heck" style={styles.bodyText} onPress={openResults} />;
    }
  };

  return (
    <View style={styles.app}>
      <View style={styles.backgroundBeer}>
        <Beer gameIndex={gameState.currentGuessIndex} status={status} />
      </View>
      <Settings shouldShow={isSettingsVisable} onClose={closeSettings} />
      <ResultsPage shouldShow={isResultsVisable} onClose={closeResults} />
        <View style={styles.banner}>
          <SettingsButton onPress={toggleSettingsVisibility} />
        </View>
      <View style={styles.contentContainer}>
        <Title style={styles.title}>
          PUB
          <br />
          GENIUS
        </Title>
        <View style={styles.content}>{getContent(status)}</View>
      </View>
      <View style={styles.debugInfo}>{getDebugInfo()}</View>
    </View>
  );
}

const getStyles = (theme) => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundColor: theme.appBG,
    overflow: 'hidden',
    color: theme.textPrimary,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  debugInfo: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    fontFamily: 'open-sans',
    fontSize: 14,
    color: theme.textPrimary,
    margin: 20,
  },
  header: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  body: {
  //   fontFamily: 'open-sans',
  //   fontSize: 14,
    marginTop: '20%', // Keep 20% distance from the top
    flex: 1, // Use flex to allow other elements to take up remaining space
    display: 'flex',
    justifyContent: 'flex-start', // Ensure content is aligned at the start (top)
    alignItems: 'center', // Center content horizontally
    // textAlign: 'center', // Center text horizontally
  },
  h1: {
  //   fontFamily: 'copperplate',
  //   fontSize: 18,
  //   fontWeight: 'bold',
    marginTop: '20%', // Keep 20% distance from the top
    flex: 1, // Use flex to allow other elements to take up remaining space
    display: 'flex',
    justifyContent: 'flex-start', // Ensure content is aligned at the start (top)
    alignItems: 'center', // Center content horizontally
  },
  h2: {
  //   fontFamily: 'copperplate',
  //   fontSize: 14,
  //   fontWeight: 'bold',
    marginTop: '20%', // Keep 20% distance from the top
    flex: 1, // Use flex to allow other elements to take up remaining space
    display: 'flex',
    justifyContent: 'flex-start', // Ensure content is aligned at the start (top)
    alignItems: 'center', // Center content horizontally
  },
  backgroundBeer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1, // Make sure Beer is in the background
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    flexGrow: 1,
  },
  banner: {
    position: 'fixed',
    top: 0,
    right: 0,
    alignItems: 'center',
    zIndex: 1000,
    padding: '0 20px',
  },
  // title: {
    // fontFamily: 'Libre Baskerville',
  //   fontWeight: 'bold',
    // textAlign: 'center',
    // fontFamily: 'Libre Baskerville',
  //   fontSize: 50,
  //   fontStyle: 'normal',
  //   fontWeight: '700',
  //   color: theme.textPrimary,
    // textAlign: 'center',
    // letterSpacing: 1,
  // },
  // bodyText: {
  //   fontSize: 18,
  //   color: theme.textPrimary,
  //   textAlign: 'center',
  // },
  button: {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default HomeScreen;
