import { useMemo, useEffect } from 'react';
import { useColorScheme } from 'react-native-web';
import { useOnyx } from 'react-native-onyx';
import CONST from '../CONST';
import ONYXKEYS from '../lib/ONYXKEYS';

function useThemePreference() {
  // Get the current theme preference from Onyx storage (initially 'light' or 'dark')
  const [preferredThemeFromStorage] = useOnyx<string>(ONYXKEYS.THEME);

  //   const systemTheme = useColorScheme();
  const systemTheme = CONST.THEME.LAGER;

  // Compute the final theme preference
  const themePreference = useMemo(() => {
    return preferredThemeFromStorage || systemTheme;
  }, [preferredThemeFromStorage, systemTheme]);

  return themePreference;
}

export default useThemePreference;
