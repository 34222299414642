const ONYXKEYS = {
  SESSION: 'session',
  STATS: 'stats',
  GAME_STATE: 'game_state',
  GAME_DATA: 'game_data',
  SETTINGS: 'settings',
  THEME: 'theme',
};

export default ONYXKEYS;
