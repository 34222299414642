import React from 'react';
import { View } from 'react-native';
import useTheme from './../hooks/useTheme.ts';
import type { Theme } from './../styles/themes.ts';

interface TitleProps {
    children?: React.ReactNode;
}

function Title({children}: TitleProps) {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <View style={styles.divider} />
  );
}

const getStyles = (theme: Theme) => ({
    divider: {
        width: '100%',
        height: 1,
        backgroundColor: theme.textPrimary,
        marginVertical: 4,
  },
});

export default Title;
