import React, { useEffect, useRef, useState } from 'react';
import { View, TextInput as RNTextInput, StyleSheet, Image, Button } from 'react-native';
import { useOnyx } from 'react-native-onyx';
import { useGameContext } from '../context/GameContext';
import ONYXKEYS from '../lib/ONYXKEYS';
import useTheme from '../hooks/useTheme.ts';
import type { Theme } from '../styles/themes.ts';
import ImageWithContent from './ImageWithContent.tsx';

interface ClueZoneProps {
  imageURL: string;
}

function hexToRgba(hex, alpha) {
  // Check if the hex color is valid
  const isValidHex = /^#([0-9A-Fa-f]{3}){1,2}$/.test(hex);
  if (!isValidHex) {
    throw new Error("Invalid hex color format. Expected #RRGGBB or #RGB.");
  }

  // If the hex color is 3 characters long (e.g., #FFF), expand it to 6 characters
  if (hex.length === 4) {
    hex = "#" + hex.slice(1).split("").map(c => c + c).join("");
  }

  // Convert the hex color to RGB
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  // Return the RGBA color
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function ClueZone({ imageURL }: ClueZoneProps) {
  const { updateGameState } = useGameContext();
  const [input, setInput] = useState('');
  const inputRef = useRef<RNTextInput | null>(null);
  const theme = useTheme();
  const styles = getStyles(theme);

  const cleanInput = (input: string) => input.trim().toUpperCase().replace(/\s+/g, ' ');

    // Handle any key press
    const handleKeyPress = () => {
      // Focus the input field whenever any key is pressed
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };
  
    // Use useEffect to listen for any key press globally
    useEffect(() => {
      // Add event listener for key press
      window.addEventListener('keydown', handleKeyPress);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }, []);

  const handleGuess = () => {
    const answer = cleanInput(input);
    if (!answer) {
      console.log('>>> MAKE GUESS SILLY');
      return;
    }
    updateGameState(answer);
    setInput('');
    setTimeout(() => inputRef.current?.focus(), 50);
  };

  return (
    <ImageWithContent imageURL={imageURL}>
        <RNTextInput
          ref={inputRef}
          autoFocus
          style={[
            styles.input,
          ]}
          placeholder="Name that pub!"
          placeholderTextColor={hexToRgba(theme.textPrimary, 0.3)}
          onChangeText={setInput}
          value={input}
          returnKeyType="done"
          onSubmitEditing={handleGuess}
          />
    </ImageWithContent>
  );
}

const getStyles = (theme: Theme) => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 28,
  },
  imageContainer: {
    width: 400, // Set the fixed width
    height: 300, // Set the fixed height
    overflow: 'hidden', // Ensures anything outside this box is cropped
    backgroundColor: theme.appBG, // Optional background color for visibility
    borderRadius: 10, // Optional: Add rounded corners
    borderColor: theme.textPrimary,
    borderWidth: 4,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  inputContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  input: {
    backgroundColor: 'transparent',
    paddingVertical: 10,  // Add vertical padding to give space for the cursor
    paddingHorizontal: 20, // Horizontal padding for the caret
    fontSize: 28,
    fontFamily: 'copperplate',
    textTransform: 'uppercase',
    textAlign: 'center', // Keep the text left
    outlineWidth: 0,
    color: theme.textPrimary,
  },
});

export default ClueZone;
