import React, { useState, useEffect } from 'react';
import { View, Text, Switch, Button, Modal } from 'react-native-web';
import Onyx from 'react-native-onyx';
import CONST from '../CONST';
import ONYXKEYS from '../lib/ONYXKEYS';
import useThemePreference from '../hooks/useThemePreference.ts';
import useTheme from '../hooks/useTheme.ts';
import H1 from './text/H1.tsx';
import H2 from './text/H2.tsx';
import Body from './text/Body.tsx';

const Settings = ({ shouldShow = true, onClose }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const themePreference = useThemePreference();
  const toggleInitialState = themePreference === CONST.THEME.GUINNESS;
  const [isEnabled, setIsEnabled] = useState(toggleInitialState);

  useEffect(() => {
    if (themePreference === CONST.THEME.GUINNESS) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [themePreference]);

  const toggleSwitch = () => {
    setIsEnabled((previousState) => {
      const newState = !previousState;

      if (newState) {
        Onyx.set(ONYXKEYS.THEME, CONST.THEME.GUINNESS);
      } else {
        Onyx.set(ONYXKEYS.THEME, CONST.THEME.LAGER);
      }

      return newState;
    });
  };

  if (!shouldShow) {
    return null;
  }

  return (
    <View style={[styles.settingsmodal, { backgroundColor: theme.beerDark }]}>
      <View>
        <View style={styles.row}>
          <H1 style={styles.h1}>Settings</H1>
          <Button title="Close" onPress={onClose} style={styles.closeButton} />
        </View>
        <View style={styles.row}>
          <Body style={styles.body}>Dark Mode</Body>
          <Switch style={styles.Switch} onValueChange={toggleSwitch} value={isEnabled} />
        </View>
      </View>
    </View>
  );
};

const getStyles = (theme) => ({
  settingsmodal: {
    position: 'absolute', // Make it absolute so it can be positioned relative to its parent
    top: 0, // Ensure it starts at the top of the screen
    left: 0, // Ensure it starts at the left of the screen
    right: 0, // Ensure it stretches to the right edge of the screen
    bottom: 0, // Ensure it stretches to the bottom edge of the screen
    // flex: 1,               // Use flex to make sure the modal takes up the full available space
    padding: 20, // Add padding inside the modal
    justifyContent: 'center', // Center content vertically
    alignItems: 'center', // Center content horizontally
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adds a semi-transparent background to darken the screen behind the modal
    zIndex: 2000, // Ensure the modal appears above other elements
  },
  h1: {
    // fontSize: 20,
    // fontWeight: 'bold',
    // color: theme.textPrimary,
  },
  body: {
    // fontSize: 16,
    // color: theme.textPrimary,
  },
  closeButton: {
    marginLeft: 10,
  },
  Switch: {
    marginLeft: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
});

export default Settings;
