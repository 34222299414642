import React from 'react';
import Onyx from './lib/onyxConfig'; // Import the Onyx configuration
import './App.css';
import HomeScreen from './screens/HomeScreen';
import { GameProvider } from './context/GameContext';
import ThemeProvider from './context/ThemeProvider.tsx';

if (typeof __DEV__ === 'undefined') {
  global.__DEV__ = process.env.NODE_ENV !== 'production';
}

function App() {
  return (
    <ThemeProvider>
      <GameProvider>
        <HomeScreen />
      </GameProvider>
    </ThemeProvider>
  );
}

export default App;
