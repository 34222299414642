import Onyx from 'react-native-onyx';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ONYXKEYS from './ONYXKEYS';

const config = {
  keys: ONYXKEYS,
  storageProvider: AsyncStorage,
  safeEviction: true,
};

Onyx.init(config);

export default Onyx;
