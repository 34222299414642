import React, { useEffect, useMemo } from 'react';
import { themes } from '../styles/themes.ts';
import type { Theme } from '../styles/themes.ts';
import ThemeContext from './ThemeContext.ts';
import useThemePreference from '../hooks/useThemePreference.ts';

type ThemeProviderProps = React.PropsWithChildren & {
  theme?: Theme;
};

function ThemeProvider({ children }: ThemeProviderProps) {
  // Get the user's theme preference (from Onyx or system)
  const themePreference = useThemePreference();

  console.log({ themePreference });

  // Use useMemo to memoize the theme object based on the themePreference
  const theme = useMemo(() => themes[themePreference], [themePreference]);

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
}

ThemeProvider.displayName = 'ThemeProvider';

export default ThemeProvider;
