import React, { useEffect, useRef, useState } from 'react';
import { View, TextInput as RNTextInput, StyleSheet, Image, Button } from 'react-native';
import { useOnyx } from 'react-native-onyx';
import { useGameContext } from '../context/GameContext';
import ONYXKEYS from '../lib/ONYXKEYS';
import useTheme from '../hooks/useTheme.ts';
import type { Theme } from '../styles/themes.ts';

interface ImageWithContentProps {
  imageURL: string;
  children?: React.ReactNode;
}

function ImageWithContent({ imageURL, children }: ImageWithContentProps) {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <View style={styles.root}>
      <View style={styles.imageContainer}>
        <Image
          source={{ uri: imageURL }}
          style={styles.image}
          resizeMode="cover"
          onError={(e) => console.log('Image loading error:', e.nativeEvent.error)}
        />
      </View>
      <View style={styles.contentContainer}>
        {children}
      </View>
    </View>
  );
}

const getStyles = (theme: Theme) => ({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 28,
  },
  imageContainer: {
    width: 400,
    height: 300,
    overflow: 'hidden',
    backgroundColor: theme.appBG,
    borderRadius: 10,
    borderColor: theme.textPrimary,
    borderWidth: 4,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

export default ImageWithContent;