import React, { useEffect, useRef, useState } from 'react';
import { Pressable, View, Dimensions } from 'react-native';
import '../App.css';
import { Animated } from 'react-native-web';
import CONST from '../CONST';
import Wave from 'react-wavify';
import useTheme from '../hooks/useTheme.ts';

let height = Dimensions.get('window').height;

function Beer({ gameIndex, status }) {
  const [screenHeight, setScreenHeight] = useState(height);
  const theme = useTheme();
  const styles = getStyles(theme);

  // Update screenHeight whenever the window size changes
  useEffect(() => {
    const onResize = () => {
      setScreenHeight(Dimensions.get('window').height);
    };

    Dimensions.addEventListener('change', onResize);

    // Cleanup on unmount
    return () => {
      Dimensions.removeEventListener('change', onResize);
    };
  }, []);

  const translateYAnim = useRef(new Animated.Value(height - 100)).current; // Animation for vertical movement

  const goDownTenPercent = () => {
    // Get the current value of the animation
    translateYAnim.stopAnimation((currentValue) => {
      // Calculate the new value (add 10% of the height)
      const newValue = Math.min(currentValue + screenHeight * 0.1, screenHeight * 0.9); // Limit to 90% of screen height
      Animated.timing(translateYAnim, {
        toValue: newValue,
        duration: 1000,
        useNativeDriver: true,
      }).start();
    });
  };

  const goToIndex = (index) => {
    // Get the current value of the animation
    translateYAnim.stopAnimation(() => {
      // Calculate the new value (add 10% of the height)
      const newValue = Math.min(screenHeight * 0.1 * index, screenHeight * 0.9); // Limit to 90% of screen height
      Animated.timing(translateYAnim, {
        toValue: newValue,
        duration: 1000,
        useNativeDriver: true,
      }).start();
    });
  };

  const startFill = () => {
    // Get the current value of the animation
    translateYAnim.stopAnimation(() => {
      // Calculate the new value (add 10% of the height)
      const newValue = Math.min(screenHeight * 0.1 * 0, screenHeight * 0.9); // Limit to 90% of screen height
      Animated.timing(translateYAnim, {
        toValue: newValue,
        duration: 3000,
        useNativeDriver: true,
      }).start();
    });
  };

  const onGameWin = () => {
    translateYAnim.stopAnimation(() => {
      Animated.timing(translateYAnim, {
        toValue: 0, // Move back up
        duration: 1000,
        useNativeDriver: true,
      }).start();
    });
  };

  const onGameLose = () => {
    translateYAnim.stopAnimation(() => {
      Animated.timing(translateYAnim, {
        toValue: 900, // Move all the way down
        duration: 1000,
        useNativeDriver: true,
      }).start();
    });
  };

  useEffect(() => {
    // Expose functions to the global scope (TEMPORARY)
    window.onGameWin = onGameWin;
    window.onGameLose = onGameLose;
    window.goToIndex = goToIndex;
    window.goDownTenPercent = goDownTenPercent;

    // Cleanup on unmount
    return () => {
      delete window.onGameWin;
      delete window.onGameLose;
      delete window.goToIndex;
      delete window.goDownTenPercent;
    };
  }, []);

  // Animate position when `gameIndex` changes
  useEffect(() => {
    if (status === CONST.STATUS.WON) {
      onGameWin();
    } else if (status === CONST.STATUS.LOST) {
      onGameLose();
    } else if (status === CONST.STATUS.IN_PROGRESS) {
      goToIndex(gameIndex);
    } else if (status === CONST.STATUS.NOT_STARTED) {
      setTimeout(() => {
        startFill();
      }, 1000);
    } else {
      goToIndex(0);
    }
  }, [gameIndex, status, screenHeight]);

  return (
      <View style={styles.container}>
        <Animated.View
          style={[
            styles.movingSection,
            {
              transform: [{ translateY: translateYAnim }],
            },
          ]}
        >
          {/* Waves */}
          <View style={[styles.waveContainer, { height: 10 }]}>
            <View style={styles.entireWave}>
              <Wave
                fill={theme.beerFoam}
                paused={false}
                options={{
                  height: 40,
                  amplitude: 20,
                  speed: 0.15,
                  points: 3,
                }}
                style={{ ...styles.wave, top: -40 }}
              />
              <Wave
                fill={theme.beerDark}
                paused={false}
                options={{
                  height: 80,
                  amplitude: 30,
                  speed: 0.15,
                  points: 3,
                }}
                style={{ ...styles.wave }}
              />
            </View>
          </View>
        </Animated.View>
      </View>
  );
}

const getStyles = (theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.appBG,
    overflow: 'hidden',
  },
  movingSection: {
    flex: 1,
    width: '100%',
  },
  waveContainer: {
    flex: 1,
    position: 'relative',
    width: '100%',
  },
  entireWave: {
    flex: 1,
  },
  wave: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});

export default Beer;
