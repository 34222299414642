const CONST = {
  STATUS: {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    WON: 'WON',
    LOST: 'LOST',
  },
  THEME: {
    LAGER: 'light',
    GUINNESS: 'dark',
  },
};

export default CONST;
