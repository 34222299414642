import React from 'react';
import { Text } from 'react-native';
import useTheme from '../../hooks/useTheme.ts';
import type { Theme } from '../../styles/themes.ts';

interface BodyProps {
    children?: React.ReactNode;
}

function Body({children}: BodyProps) {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Text style={styles.body}>
        {children}
    </Text>
  );
}

const getStyles = (theme: Theme) => ({
  body: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 300,
    fontStyle: 'regular',
    color: theme.textPrimary,
  },
});

export default Body;