import React from 'react';
import { View, Text, Button } from 'react-native-web';
import Onyx, { useOnyx } from 'react-native-onyx';
import CONST from '../CONST';
import ONYXKEYS from '../lib/ONYXKEYS';
import { useGameContext } from '../context/GameContext';
import useTheme from '../hooks/useTheme.ts';
import ImageWithContent from './ImageWithContent.tsx';
import H1 from './text/H1.tsx';
import H2 from './text/H2.tsx';
import Body from './text/Body.tsx';
import Title from './text/Title.tsx';
import Divider from './Divider.tsx';

function ResultsPage({ pubName, pubLocation, pubImageURL, shouldShow, onClose }) {
  const [pubData] = useOnyx(ONYXKEYS.GAME_DATA);
  const { gameState } = useGameContext();
  const status = gameState?.status || '';
  const theme = useTheme();
  const styles = getStyles(theme);

  if (!gameState) {
    return <Text>Loading...</Text>;
  }

  if (!pubData) {
    return <Text>Loading...</Text>;
  }
  const getContent = (status) => {
    switch (status) {
      case CONST.STATUS.WON:
        return <Title>Cheers!</Title>;
      case CONST.STATUS.LOST:
        return <Title>You're Barred!</Title>;
      default:
        return <Title>Loading...</Title>;
    }
  };

  if (!shouldShow) {
    return null;
  }

  return (
    <>
      <View style={styles.root}>
        <View style={styles.closeButton}>
          <Button title="Close" style={styles.closeButton} onPress={onClose} />
        </View>
        <View style={styles.contentContainer}>{getContent(status)}
          <View style={styles.content}>
          <ImageWithContent imageURL={pubData.imageUrls[4]} >
            <H1>{pubData.name}</H1>
          </ImageWithContent>
          <Divider />
          <View style={styles.pubInfo}>
            <View style ={styles.pubLocationFounded}>
              <Body>Location: {pubData.location}</Body>
              <Body style={styles.body}>Founded: {pubData.founded}</Body>
            </View>
            <Body style={styles.body}>{pubData.fact}</Body>
          </View>
            <H1 style={styles.H1}>Results</H1>
            <Divider />
             <View style={styles.row}>
              <H2 style={styles.label}>Score:{6 - gameState.currentGuessIndex}</H2>
            </View>
            <View style={styles.row}>
              <H2 style={styles.label}>Pubs:</H2>
            </View>
            <H2 style={styles.label}>Streak:</H2>
            <View style={styles.row}>
              <Button title="Share" style={styles.shareButton} />
            </View>
          </View>
        </View>
      </View>
    </>
  );
}

const getStyles = (theme) => ({
  root: {
    backgroundColor: theme.appBG,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flex: 1,
    zIndex: 2000,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto', // Enable vertical scrolling
    overflowX: 'hidden', // Prevent horizontal scrolling
    color: theme.textPrimary,
  },
  pubInfo: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: '90%', // Ensure it doesn't exceed screen width
    width: '100%', // Allow it to scale
    wordWrap: 'break-word', // Handle long text
  },
  pubLocationFounded: {
    marginBottom: 20,
  },
  closeButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex',
    alignItems: 'right',
    zIndex: 1000,
    margin: 10,
    padding: 20,
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start', // Start content at the top
    width: '100%',
    maxWidth: 800, // Restrict width for better readability
    margin: '0 auto', // Center content horizontally
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start', // Prevent content from growing too much
    padding: 20,
    flexGrow: 1,
    gap: 20, // Use gap for consistent spacing
    width: '100%', // Allow it to scale
    maxWidth: '90%', // Prevent horizontal overflow
    boxSizing: 'border-box', // Ensure padding doesn’t add to width
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%', // Use full width of the container
    maxWidth: 600, // Prevent overflow in small screens
    flexWrap: 'wrap', // Allow rows to wrap if content overflows
    gap: 10, // Add spacing between items
  },
  shareButton: {
    marginTop: 20,
  },
});

export default ResultsPage;
