import React from 'react';
import { Text } from 'react-native';
import useTheme from '../../hooks/useTheme.ts';
import type { Theme } from '../../styles/themes.ts';

interface H1Props {
    children?: React.ReactNode;
}

function H1({children}: H1Props) {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Text style={styles.h1}>
        {children}
    </Text>
  );
}

const getStyles = (theme: Theme) => ({
  h1: {
    fontFamily: 'copperplate',
    fontSize: 28,
    fontWeight: 700,
    fontStyle: 'bold',
    color: theme.textPrimary,
    textAlign: 'center',
  },
});

export default H1;