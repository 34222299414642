import { useContext } from 'react';
import ThemeContext from '../context/ThemeContext.ts';
import type { Theme } from '../styles/themes.ts';

function useTheme(): Theme {
  const theme = useContext(ThemeContext);

  if (!theme) {
    throw new Error('ThemeContext was null.');
  }

  return theme;
}

export default useTheme;
